import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";

type Props = {
  title: string;
  description: string;
};

function ComingSoonPage({ title, description }: Props) {
  return (
    <div>
      <h1 className="mb-5 text-3xl font-bold">{title}</h1>
      <h2 className="mb-5 text-slate-400">{description}</h2>
      <div className="mt-24 flex w-full justify-center">
        <div className="flex w-[409px] flex-col items-center gap-4">
          <h1 className="text-3xl font-bold">Apply for Private Beta</h1>
          <h2 className="text-center text-slate-400">
            This Agent is currently in private beta, book a demo to learn more.
          </h2>
          <Button size="lg">
            <Link
              to="https://meetings.hubspot.com/skyvern/demo"
              target="_blank"
              rel="noopener noreferrer"
            >
              Book a Demo
            </Link>
          </Button>
        </div>
      </div>
    </div>
  );
}

export { ComingSoonPage };
