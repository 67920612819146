import { CreateTaskRequest, ProxyLocation } from "@/api/types";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Separator } from "@/components/ui/separator";
import { Textarea } from "@/components/ui/textarea";
import { zodResolver } from "@hookform/resolvers/zod";
import { ReloadIcon, StarIcon } from "@radix-ui/react-icons";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { FormsRunHistory } from "./FormsRunHistory";
import { useApiCredential } from "@/hooks/useApiCredential";
import axios from "axios";
import { apiBaseUrl } from "@/util/env";
import { getClient } from "@/api/AxiosClient";
import { useCredentialGetter } from "@/hooks/useCredentialGetter";

const formsAgentClient = axios.create({
  baseURL: apiBaseUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

function createFormsAgentTaskRequest(values: {
  url: string;
  payload: Record<string, unknown>;
  goal: string;
}): CreateTaskRequest {
  return {
    title: "Skyvern Forms Task",
    url: values.url,
    navigation_payload: values.payload,
    navigation_goal: values.goal,
    proxy_location: ProxyLocation.Residential,
    application: "forms",
  };
}

const urlPlaceholder = `example1.com\nexample2.com/contact\nexample3.com/home`;
const additionalInformationPlaceholder = `Message: I'd love to learn more about your...\nPhone: 123-456-7890\nInquiry type: Sales`;

const contactFormTaskSchema = z.object({
  urls: z.string().min(1, {
    message: "At least one site URL is required",
  }),
  name: z.string(),
  email: z.string(),
  information: z.string(),
  navigationGoal: z.string(),
});

type contactFormTaskValues = z.infer<typeof contactFormTaskSchema>;

function getTrimmedLines(str: string): Array<string> {
  return str.split("\n").map((line) => line.trim());
}

const FAST_QUEUE_TASK_COUNT_THRESHOLD = 5;

function FormsPage() {
  const queryClient = useQueryClient();
  const credentialGetter = useCredentialGetter();
  const [isLoading, setIsLoading] = useState(false);
  const [showAdvancedContent, setShowAdvancedContent] = useState(false);
  const apiKey = useApiCredential();
  const form = useForm<contactFormTaskValues>({
    resolver: zodResolver(contactFormTaskSchema),
    defaultValues: {
      urls: "",
      email: "",
      information: "",
      name: "",
      navigationGoal:
        "Fill out the contact us form and submit it. Your goal is complete when the page says your message has been sent.",
    },
  });

  const createFormAgentTaskInFastQueueMutation = useMutation({
    mutationFn: async (values: {
      url: string;
      payload: Record<string, unknown>;
      goal: string;
    }) => {
      const body = createFormsAgentTaskRequest(values);
      const client = await getClient(credentialGetter);
      return client.post<
        ReturnType<typeof createFormsAgentTaskRequest>,
        { data: { task_id: string } }
      >("/tasks", body, {
        headers: {
          "x-max-steps-override": 5,
        },
      });
    },
  });

  const createFormAgentTaskInBatchQueueMutation = useMutation({
    mutationFn: async (values: {
      url: string;
      payload: Record<string, unknown>;
      goal: string;
    }) => {
      if (!apiKey) {
        return;
      }
      const body = createFormsAgentTaskRequest(values);
      return formsAgentClient.post<
        ReturnType<typeof createFormsAgentTaskRequest>,
        { data: { task_id: string } }
      >("/tasks", body, {
        headers: {
          "x-max-steps-override": 4,
          "x-api-key": apiKey,
        },
      });
    },
  });

  async function onSubmit(values: contactFormTaskValues) {
    const { urls, email, information, name } = values;
    const parsedUrls = getTrimmedLines(urls);

    const fastQueueTaskUrls = parsedUrls.slice(
      0,
      FAST_QUEUE_TASK_COUNT_THRESHOLD,
    );
    const batchQueueTaskUrls = parsedUrls.slice(
      FAST_QUEUE_TASK_COUNT_THRESHOLD,
    );

    const batchQueuePromises = batchQueueTaskUrls.map((url) => {
      return createFormAgentTaskInBatchQueueMutation.mutateAsync({
        url,
        payload: {
          additionalInformation: information,
          email,
          name,
        },
        goal: values.navigationGoal,
      });
    });

    const fastQueuePromises = fastQueueTaskUrls.map((url) => {
      return createFormAgentTaskInFastQueueMutation.mutateAsync({
        url,
        payload: {
          additionalInformation: information,
          email,
          name,
        },
        goal: values.navigationGoal,
      });
    });
    setIsLoading(true);
    await Promise.all(batchQueuePromises);
    await Promise.all(fastQueuePromises);
    queryClient.invalidateQueries({
      queryKey: ["skyvernFormsTasks"],
    });
    setIsLoading(false);
  }

  return (
    <div className="space-y-6">
      <h1 className="text-3xl">Contact Forms</h1>
      <section className="flex items-stretch gap-8 bg-slate-elevation1 p-6">
        <div className="w-full">
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <div className="space-y-6 py-6">
                <FormField
                  control={form.control}
                  name="urls"
                  render={({ field }) => {
                    return (
                      <FormItem>
                        <div className="space-y-4">
                          <FormLabel className="text-base">Site URL</FormLabel>
                          <FormControl>
                            <Textarea
                              placeholder={urlPlaceholder}
                              rows={3}
                              {...field}
                            />
                          </FormControl>
                          <FormMessage />
                        </div>
                      </FormItem>
                    );
                  }}
                />
                <FormField
                  control={form.control}
                  name="name"
                  render={({ field }) => {
                    return (
                      <FormItem>
                        <div className="space-y-4">
                          <FormLabel className="text-base">Name</FormLabel>
                          <FormControl>
                            <Input
                              placeholder="The name you want associated with the submission"
                              {...field}
                            />
                          </FormControl>
                          <FormMessage />
                        </div>
                      </FormItem>
                    );
                  }}
                />
                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => {
                    return (
                      <FormItem>
                        <div className="space-y-4">
                          <FormLabel className="text-base">Email</FormLabel>
                          <FormControl>
                            <Input
                              placeholder="Where you want replies to go"
                              {...field}
                            />
                          </FormControl>
                          <FormMessage />
                        </div>
                      </FormItem>
                    );
                  }}
                />
                <FormField
                  control={form.control}
                  name="information"
                  render={({ field }) => {
                    return (
                      <FormItem>
                        <div className="space-y-4">
                          <FormLabel className="text-base">
                            Additional Information
                          </FormLabel>
                          <FormControl>
                            <Textarea
                              placeholder={additionalInformationPlaceholder}
                              rows={3}
                              {...field}
                            />
                          </FormControl>
                          <FormMessage />
                        </div>
                      </FormItem>
                    );
                  }}
                />
                {showAdvancedContent ? (
                  <div className="border-t border-dashed pt-4">
                    <FormField
                      control={form.control}
                      name="navigationGoal"
                      render={({ field }) => (
                        <FormItem>
                          <div className="space-y-4">
                            <FormLabel>
                              <h1 className="text-base">Default Prompt</h1>
                            </FormLabel>
                            <div className="w-full">
                              <FormControl>
                                <Textarea rows={3} {...field} />
                              </FormControl>
                              <FormMessage />
                              <Button
                                className="mt-4"
                                type="button"
                                variant="tertiary"
                                onClick={() => {
                                  setShowAdvancedContent(false);
                                }}
                                size="sm"
                              >
                                Hide Advanced Settings
                              </Button>
                            </div>
                          </div>
                        </FormItem>
                      )}
                    />
                  </div>
                ) : (
                  <div>
                    <Button
                      type="button"
                      variant="tertiary"
                      onClick={() => {
                        setShowAdvancedContent(true);
                      }}
                      size="sm"
                    >
                      Show Advanced Settings
                    </Button>
                  </div>
                )}
              </div>
              <div className="flex justify-end">
                <Button type="submit" className="w-24">
                  {isLoading && (
                    <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
                  )}
                  Run
                </Button>
              </div>
            </form>
          </Form>
        </div>
        <div className="flex w-[448px] shrink-0 flex-col gap-6 rounded-lg bg-slate-elevation2 p-6">
          <h2 className="text-lg font-bold">Contact Form Agent</h2>
          <h3>
            Skyvern can navigate to any website and fill out the contact form
            with the information supplied here
          </h3>
          <Separator />
          <div className="flex flex-col gap-4">
            <div className="flex items-center gap-6">
              <StarIcon className="size-6 shrink-0" />
              <div className="text-sm text-slate-300">
                Gather your lead list: get a URL, and collect information that
                you would like to submit for each lead
              </div>
            </div>
            <div className="flex items-center gap-6">
              <StarIcon className="size-6 shrink-0" />
              <div className="text-sm text-slate-300">
                If you are adding multiple values at a time, make sure they are
                line-separated
              </div>
            </div>
            <div className="flex items-center gap-6">
              <StarIcon className="size-6 shrink-0" />
              <div className="text-sm text-slate-300">
                Make sure the additional information section covers as much
                information as you'd like. Skyvern will pull information from it
                to answer the specific questions that may be asked
              </div>
            </div>
          </div>
          <div className="mt-auto text-sm text-slate-400">
            Want to use a Google Sheet + our Zapier integration instead?
            <a
              target="_blank"
              className="text-primary underline underline-offset-2"
              rel="noopener noreferrer"
              href="https://docs.google.com/spreadsheets/d/1Z3RJhHlWuDbR4itzlbp37fW-UH-0XhVSYaLwra8-koE/edit?gid=2085174777#gid=2085174777"
            >
              Press here to download.
            </a>
          </div>
        </div>
      </section>
      <FormsRunHistory />
    </div>
  );
}

export { FormsPage };
