import { SettingsPageLayout } from "@/routes/settings/SettingsPageLayout";
import { CreateNewTaskFormPage } from "@/routes/tasks/create/CreateNewTaskFormPage";
import { RetryTask } from "@/routes/tasks/create/retry/RetryTask";
import { StepArtifactsLayout } from "@/routes/tasks/detail/StepArtifactsLayout";
import { TaskActions } from "@/routes/tasks/detail/TaskActions";
import { TaskDetails } from "@/routes/tasks/detail/TaskDetails";
import { TaskParameters } from "@/routes/tasks/detail/TaskParameters";
import { TaskRecording } from "@/routes/tasks/detail/TaskRecording";
import { TasksPage } from "@/routes/tasks/list/TasksPage";
import { TasksPageLayout } from "@/routes/tasks/TasksPageLayout";
import { WorkflowEditor } from "@/routes/workflows/editor/WorkflowEditor";
import { WorkflowPage } from "@/routes/workflows/WorkflowPage";
import { WorkflowRun } from "@/routes/workflows/WorkflowRun";
import { WorkflowRunParameters } from "@/routes/workflows/WorkflowRunParameters";
import { Workflows } from "@/routes/workflows/Workflows";
import { WorkflowsPageLayout } from "@/routes/workflows/WorkflowsPageLayout";
import { Navigate, Outlet, createBrowserRouter } from "react-router-dom";
import { Billing } from "./routes/billing/Billing";
import { BillingPageLayout } from "./routes/billing/BillingPageLayout";
import { FormsPage } from "./routes/forms/FormsPage";
import { FormsPageLayout } from "./routes/forms/FormsPageLayout";
import { Root } from "./routes/root/Root";
import { RootLayout } from "./routes/root/RootLayout";
import { CloudSettings } from "./routes/settings/CloudSettings";
import { SignInPage } from "./routes/sign-in/SignInPage";
import { SignUpPage } from "./routes/sign-up/SignUpPage";
import { ComingSoonPage } from "./routes/forms/ComingSoonPage";
import { WorkflowRunBlocks } from "@/routes/workflows/workflowRun/WorkflowRunBlocks";
import { WorkflowRunOutput } from "@/routes/workflows/workflowRun/WorkflowRunOutput";
import { WorkflowRunRecording } from "@/routes/workflows/workflowRun/WorkflowRunRecording";
import { WorkflowPostRunParameters } from "@/routes/workflows/workflowRun/WorkflowPostRunParameters";

const router = createBrowserRouter([
  {
    element: <Root />,
    children: [
      {
        path: "/sign-in/*",
        element: <SignInPage />,
      },
      {
        path: "/sign-up/*",
        element: <SignUpPage />,
      },
      {
        path: "/",
        element: <RootLayout />,
        children: [
          {
            index: true,
            element: <Navigate to="/tasks" />,
          },
          {
            path: "tasks",
            element: <TasksPageLayout />,
            children: [
              {
                index: true,
                element: <TasksPage />,
              },
              {
                path: "create",
                element: <Outlet />,
                children: [
                  {
                    path: ":template",
                    element: <CreateNewTaskFormPage />,
                  },
                  {
                    path: "retry/:taskId",
                    element: <RetryTask />,
                  },
                ],
              },
              {
                path: ":taskId",
                element: <TaskDetails />,
                children: [
                  {
                    index: true,
                    element: <Navigate to="actions" />,
                  },
                  {
                    path: "actions",
                    element: <TaskActions />,
                  },
                  {
                    path: "recording",
                    element: <TaskRecording />,
                  },
                  {
                    path: "parameters",
                    element: <TaskParameters />,
                  },
                  {
                    path: "diagnostics",
                    element: <StepArtifactsLayout />,
                  },
                ],
              },
            ],
          },
          {
            path: "workflows",
            element: <WorkflowsPageLayout />,
            children: [
              {
                index: true,
                element: <Workflows />,
              },
              {
                path: ":workflowPermanentId",
                element: <Outlet />,
                children: [
                  {
                    index: true,
                    element: <Navigate to="runs" />,
                  },
                  {
                    path: "edit",
                    element: <WorkflowEditor />,
                  },
                  {
                    path: "run",
                    element: <WorkflowRunParameters />,
                  },
                  {
                    path: "runs",
                    element: <WorkflowPage />,
                  },
                  {
                    path: ":workflowRunId",
                    element: <WorkflowRun />,
                    children: [
                      {
                        index: true,
                        element: <Navigate to="blocks" />,
                      },
                      {
                        path: "blocks",
                        element: <WorkflowRunBlocks />,
                      },
                      {
                        path: "output",
                        element: <WorkflowRunOutput />,
                      },
                      {
                        path: "parameters",
                        element: <WorkflowPostRunParameters />,
                      },
                      {
                        path: "recording",
                        element: <WorkflowRunRecording />,
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            path: "settings",
            element: <SettingsPageLayout />,
            children: [
              {
                index: true,
                element: <CloudSettings />,
              },
            ],
          },
          {
            path: "billing",
            element: <BillingPageLayout />,
            children: [
              {
                index: true,
                element: <Billing />,
              },
            ],
          },
          {
            path: "forms",
            element: <FormsPageLayout />,
            children: [
              {
                index: true,
                element: <FormsPage />,
              },
            ],
          },
          {
            path: "jobs",
            element: <FormsPageLayout />,
            children: [
              {
                index: true,
                element: (
                  <ComingSoonPage
                    title="Jobs"
                    description="Skyvern's Jobs Agent allows you to apply to any job application you find online"
                  />
                ),
              },
            ],
          },
          {
            path: "invoices",
            element: <FormsPageLayout />,
            children: [
              {
                index: true,
                element: (
                  <ComingSoonPage
                    title="Invoices"
                    description="Skyvern's Invoices Agent allows you to download invoices from any website"
                  />
                ),
              },
            ],
          },
          {
            path: "purchasing",
            element: <FormsPageLayout />,
            children: [
              {
                index: true,
                element: (
                  <ComingSoonPage
                    title="Purchasing"
                    description="Skyvern's Purchasing Agent allows you to make payments on the web"
                  />
                ),
              },
            ],
          },
          {
            path: "government",
            element: <FormsPageLayout />,
            children: [
              {
                index: true,
                element: (
                  <ComingSoonPage
                    title="Government"
                    description="Skyvern's Government Agent allows you to navigate any government websites"
                  />
                ),
              },
            ],
          },
        ],
      },
    ],
  },
]);

export { router };
